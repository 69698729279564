// @ts-check
import { ENV_MODE } from '@constants'
import { cs, C_TYPE } from './console'

// all in one error reporting service. log to console when in dev, send to error-reporting
// platform when in prod.

// intentionally used the word log for less typing. log is shorter than report.
export const log = {
  l: (payload, consoleLabel) => execute(C_TYPE.L, payload, consoleLabel),
  e: (payload, consoleLabel) => execute(C_TYPE.E, payload, consoleLabel),
  i: (payload, consoleLabel) => execute(C_TYPE.I, payload, consoleLabel),
  w: (payload, consoleLabel) => execute(C_TYPE.W, payload, consoleLabel),
}

const execute = (t, payload, consoleLabel) => {
  // log to console if in dev
  logDev(t, payload, consoleLabel)
  sendToProdReporter(t, payload)
}

const logDev = (t, payload, consoleLabel) => {
  // eslint-disable-next-line
  if (process.env.NODE_ENV === ENV_MODE.PROD) return

  switch (t) {
    case C_TYPE.L:
      cs.l(payload, consoleLabel)
      break
    case C_TYPE.E:
      cs.e(payload, consoleLabel)
      break
    case C_TYPE.W:
      cs.w(payload, consoleLabel)
      break
    case C_TYPE.I:
      cs.i(payload, consoleLabel)
      break
    default:
      cs.w(payload, consoleLabel)
      break
  }
}

const sendToProdReporter = (t, payload) => {
  // eslint-disable-next-line
  // @ts-ignore
  if (!process.env.NODE_ENV === ENV_MODE.PROD) return

  return null

  // todo: report to error reporter, e.g. bugsnag etc
}
